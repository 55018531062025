.multiEdit {
  font-size: 12px;
}

.multiEdit .ReactTable .rt-th, 
.multiEdit .ReactTable .rt-td {
  padding: 2px;
}

.multiEdit .ReactTable .rt-resizer {
  width: 10px;
  right: -5px;
}

.multiEdit .ReactTable select {
  width: 100%;
}

.multiEdit .ReactTable button {
  width: 20px;
  height: 20px;
  min-width: 20px;
}

.multiEdit .ReactTable input[type="checkbox"] {
  margin: 5px 0;
}

.multiEdit .upDownButton {
  width: 12px;
  height: 12px;
  line-height: 12px;
}

.multiEdit .upDownButton.enabled {
  cursor: pointer;
  fill: rgb(115, 115, 115);
}

.multiEdit .upDownButton.disabled {
  fill: rgb(175, 175, 175);
}

.multiEdit .upDownButton.enabled:hover {
  background: rgba(158, 158, 158, 0.2);
}

.rt-table {
  overflow-y: hidden !important;
  padding-bottom: 8px;
}
.rt-table .rt-tbody {
  overflow: hidden !important;
}

.text-center {
  text-align: center;
  margin: 0 auto;
  display: inline-block;
}

.text-right input {
  text-align: right;
}

.rt-td textarea {
  resize: none; 
  overflow: hidden; 
  overflow-wrap: break-word; 
  height: 35px;
}

/* hide the customer field in the main cpq screen */
.contentPanel address {
  visibility: hidden;
  height: 10px;
}

/* hide the Add Custom Line in the main cpq screen */
.contentPanel #addLineComponent ul[style*='padding'] li:last-of-type {
  display: none;
}

/* hide the menu with additional header actions */
.contentPanel .list-inline > li:last-of-type {
  display: none;
}

/* hide the menu with additional line actions on headers */
.contentPanel .row > div.text-right:last-of-type > div:last-of-type {
  visibility: hidden;
}